import React from "react";
import "./index.scss";

import {DotsGroup, X} from "src/static/img";

const About = () => {
    return (
        <div
            id="about"
            className="max-w-[1440px] px-[32px] md:px-[64px] lg:px-[85px] mx-auto overflow-hidden flex items-center py-[100px] flex-col relative md:flex-row justify-between"
        >
            <div className=" z-0 flex items-center justify-start flex-[2]">
                <h2 className=" text-white font-semibold text-4xl sm:text-5xl  z-20  relative ">
                    <img src={DotsGroup} alt="dots" className=" dotsAboutSection  "/>
                    <div className="lineAboutHeading left-[60%] sm:left-[70%]  "/>
                    About <br/> <em className=" not-italic text-red-600">
                    TEDx
                </em> CUSAT{" "}
                </h2>
            </div>
            <div className="py-10 z-20 relative flex items-center justify-center flex-[3]">
                <p className=" text-white font-normal text-xl  leading-8  ">
                    Diversity is a reality. Stereotypes are a construct. Embrace the former, challenge the latter.
                    After much anticipation, TEDxCUSAT’23 is almost here.
                    This year we bring to you stories of those who broke free from the chains of society stories of
                    those who sore high to reach their dreams.
                    Stories of those who reframed radicals and unveiled the beauty of life.
                    On november 19th 2023
                    We present to you
                    <h3 className={'font-bold'}>DIVERGENCE: Reframing radicals.</h3>
                </p>
                <img
                    src={X}
                    alt="X"
                    className="z-[-1] absolute bottom-[-40px] left-[50%] w-full h-1/2 translate-x-[-50%] opacity-20 md:opacity-40 md:left-[100%] md:translate-x-[-80%] md:bottom-[-10%] "
                />
            </div>
        </div>
    );
};

export default About;
