import React from "react";
import "./index.scss";
import SpeakerCard from "src/components/SpeakerCard";
import { Aasim,
    ANN,
    Divya,
    Lakshmi,
    Steffy,
    Kavya } from "src/static/img";
interface SpeakersData {
  name: string;
  talk: string;
  desc: string;
  image: string;
}

const speakersData: SpeakersData[] = [
  {
    name: "Steffy Sunny",
    talk: "Content Creator",
    desc: "Steffy Sunny, internet content creator, has gone from creating relatable Mallu mom reels to collaborating with the Dulquer Salman, we are honored to present none other than the beloved Mercy Aunty and Malayali's favorite content creator",
    image: Steffy,
  },
  {
    name: "Padma Lakshmi",
    talk: "Lawyer",
    desc: "Padma Lakshmi, the first transgender lawyer in the state, has not only shattered stereotypes but also stands as a symbol of resilience, determination, and inclusivity. Her journey as a lawyer is marked by groundbreaking achievements and a commitment to advocating for justice and equality.",
    image: Lakshmi,
  },
  {
    name: "Mohammed Aasim",
    talk: "COO at Protectol Health",
    desc: "Mohamed Aasim is a remarkable individual with a track record of more than a decade in diverse industries. Currently, he serves as the Chief Operating Officer (COO) at Protectol Health. Aasim's career journey showcases his entrepreneurial drive and a deep enthusiasm for the intersection of technology and pharmaceuticals. His primary focus lies in Health & Safety, and he is dedicated to lifelong learning and professional development.",
    image: Aasim,
  },
  {
    name: "Divya S Iyer",
    talk: "IAS Officer",
    desc: "Dr. Divya S. Iyer, a distinguished figure embodying excellence in diverse realms. She currently holds key roles as the Managing Director of Vizhinjam International Seaport Ltd and Project Director of Kerala Solid Waste Management Project. Dr. Iyer navigates the intricate intersection of public service, arts & literature, showcasing a unique equilibrium between unwavering commitment to public welfare and personal growth.",
    image: Divya,
  },
  {
    name: "Kavya Ajit",
    talk: "Musician",
    desc: " Kavya Ajit, an enchanting musician whose melodies transcend boundaries. With roots in a rich musical heritage, she has emerged as a versatile artist, captivating audiences with soulful renditions and seamless genre blending. Kavya's ability to weave intricate compositions and infuse emotion into each note is a testament to her dedication and artistry.",
    image: Kavya,
  },
  {
    name: "Ann Mary Philip",
    talk: "Indian Open International Kickboxing Champion & MMA Fighter",
    desc: "Ann Mary Philip, a remarkable kickboxer whose journey unfolds as a narrative of strength, resilience, and breaking barriers. Hailing from a background where societal norms often clash with personal aspirations, Ann Mary not only defied expectations but emerged as a symbol of empowerment.\n" +
        "A mother and a boxer, she navigated the demanding arenas of both roles, showcasing a unique blend of determination and passion.",
    image: ANN,
  },
];

const Speakers = () => {
  return (
    <div
      id="speakers"
      className="speakersSection cursor-grab mx-auto max-w-[1440px] px-[32px] md:px-[64px] lg:px-[85px] py-10 flex flex-col items-center md:items-start overflow-hidden"
    >
      <div className=" z-0 py-5 flex-1 flex items-center justify-center w-full sm:w-auto">
        <h2 className=" text-white font-semibold text-4xl sm:text-5xl z-20 w-full  relative ">
          <div className=" absolute w-[120px] h-[10px] bottom-0 bg-[#ff2b06] rounded-[2px] z-[-1] left-[60%] sm:left-[70%]  " />
          TALKS & <br /> SPEAKERS '23
        </h2>
      </div>
      {/*<p className=" py-5 font-medium text-lg leading-5 text-[#FAFAFA]">*/}
      {/*  Will be announced soon!*/}

      {/*</p>*/}
      <div className="flex select-none items-stretch flex-row flex-nowrap cursor-grab overflow-x-auto mt-10">
        <div className=" py-5 flex items-stretch flex-row  flex-nowrap overflow-x-auto overflow-y-hidden ">
          {speakersData?.map(({ name, talk, desc, image }, key) => {
            return <SpeakerCard key={key} {...{ name, talk, desc, image }} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Speakers;
