import React, { useState, useEffect } from 'react';
import './index.scss';
interface TimeRemaining {
  total: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const CountdownClock: React.FC = () => {
  const deadline = new Date('2023-11-19T09:00:00');

  const calculateTimeRemaining = (endTime: Date): TimeRemaining => {
    const t = Date.parse(endTime.toString()) - Date.parse(new Date().toString());
    const seconds = Math.floor((t / 1000) % 60);
    const minutes = Math.floor((t / 1000 / 60) % 60);
    const hours = Math.floor((t / (1000 * 60 * 60)) % 24);
    const days = Math.floor(t / (1000 * 60 * 60 * 24));

    return {
      total: t,
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const [timeRemaining, setTimeRemaining] = useState<TimeRemaining>(calculateTimeRemaining(deadline));

  useEffect(() => {
    let timeInterval: NodeJS.Timeout;

    const updateClock = () => {
      const t = calculateTimeRemaining(deadline);
      setTimeRemaining(t);

      if (t.total <= 0) {
        clearInterval(timeInterval);
      }
    };

    updateClock();
    timeInterval = setInterval(updateClock, 1000);

    return () => {
      clearInterval(timeInterval);
    };
  }, []); // Empty dependency array to run the effect only once on mount

  return (
    <div id="clockdiv">
      <div>
        <span className="days">{('0' + timeRemaining.days).slice(-2)}</span>
        <div className="smalltext">Days</div>
      </div>
      <div>
        <span className="hours">{('0' + timeRemaining.hours).slice(-2)}</span>
        <div className="smalltext">Hours</div>
      </div>
      <div>
        <span className="minutes">{('0' + timeRemaining.minutes).slice(-2)}</span>
        <div className="smalltext">Minutes</div>
      </div>
      <div>
        <span className="seconds">{('0' + timeRemaining.seconds).slice(-2)}</span>
        <div className="smalltext">Seconds</div>
      </div>
    </div>
  );
};

export default CountdownClock;
