import { getEnv } from "../utils";

const ENV = getEnv();

const local = {
  ENV: "local",
  API_URL: "http://localhost:8000",
  BACKEND_URL: "http://localhost:8000",
  // BACKEND_URL: "https://api.tedxcusat.in",
  FIREBASE_CONFIG: {
    apiKey: "AIzaSyDr4LaSIU3kcaoHn2Fu3xN4NWu3biupJiw",
    authDomain: "tedx-new.firebaseapp.com",
    projectId: "tedx-new",
    storageBucket: "tedx-new.appspot.com",
    messagingSenderId: "884104138632",
    appId: "1:884104138632:web:ce7a4f74e1b603dae6343d",
    measurementId: "G-ZYVGNMP5LQ",
  },

  STRIPE_PUBLIC_KEY:
    "pk_test_51KgqoYSBTn8qJLRGREUH12CPCePLEzEttHVd0yzaApJk5eEQf1W9RvRDKRNSLx3Dy72yu7GA8aWsvcYgTpkjoRJ500mVvowNgg",
};

const development = {
  ...local,

  ENV: "development",
  API_URL: "http://localhost:5000",
  // BACKEND_URL: "https://api.tedxcusat.in",
  BACKEND_URL: "http://localhost:8000",
};

const production = {
  ...development,

  ENV: "production",
  API_URL: "https://api.tedxcusat.in",
  BACKEND_URL: "https://api.tedxcusat.in",
  STRIPE_PUBLIC_KEY:
    "pk_live_51KgqoYSBTn8qJLRG2nFo3LkU5iBTKiCUIVpNY0rA208BLGdsIZdqNDHnNvPtq8KuzC3WQFXRBSFN7Hf4ZugiISKr00WJiMHJu1",
};

const configs = { local, development, production };

const currentConfig = configs[ENV];
const config = {
  ENV: currentConfig.ENV,
  API_URL: currentConfig.API_URL,
  FIREBASE_CONFIG: currentConfig.FIREBASE_CONFIG,
  BACKEND_URL: currentConfig.BACKEND_URL,
  APP_CHECK_KEY: "6LfVuXoeAAAAAPNc_6yUfYD0OsnBq2hjrvHa4vzR",
  STRIPE_PUBLIC_KEY: currentConfig.STRIPE_PUBLIC_KEY,
  STRIPE_API_KEY: currentConfig.STRIPE_API_KEY,
};

export default config;
