import React from "react";

const Join = () => {

    return (
        <>

            <iframe className="airtable-embed airtable-dynamic-height"
                    src="https://airtable.com/embed/shr1UochbJlnQRkJO?backgroundColor=red" frameBorder="0"
                    width="100%" height="100%"
            ></iframe>
        </>
    );
};

export default Join;
